import { useTranslation } from 'react-i18next';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from '../buttons/Button';
import Toggle from '../misc/Toggle';
import DropdownSelect from './DropdownSelect';
import DatePicker from './DatePicker';
import { Frequency } from '../../../types/event';
import Labeled from '../misc/Labeled';

export interface EventDatesInfo {
  startTime?: Date;
  endTime?: Date;
  isRecurring: boolean;
  recurrenceEndDate?: Date;
  frequency?: Frequency;
  deadline?: Date;
}

interface EventDatesFormProps {
  info: EventDatesInfo;
  setInfo: (dates: EventDatesInfo) => void;
  staticModal?: boolean;
  setView?: (view: 'FORM' | 'DATE') => void;
  includeRecurrence?: boolean;
  className?: string;
}

export default function EventDatesForm({
  info,
  setInfo,
  includeRecurrence = true,
  staticModal = false,
  className,
  setView = () => {},
}: EventDatesFormProps) {
  const { startTime, endTime, isRecurring, recurrenceEndDate, frequency, deadline } = info;

  const { t } = useTranslation();

  const minDate = useRef<Date>(new Date());

  const endTimeInputRef = useRef<HTMLInputElement>(null);
  const [addEndFlag, setAddEndFlag] = useState<boolean>(!!info.endTime);
  const [staticOpen, setStaticOpen] = useState<'start' | 'end' | 'recurrence'>();

  useLayoutEffect(() => setView(staticOpen ? 'DATE' : 'FORM'), [staticOpen]);

  const setStartTime = (date: Date | undefined) => {
    if (!date) return;
    const endDate = new Date(date);
    endDate.setHours(date.getHours() + 1);
    setInfo({
      ...info,
      startTime: date,
      endTime: endTime ? (new Date(endTime) < date ? endDate : endTime) : undefined,
    });
  };

  const setEndTime = (date: Date | undefined) => setInfo({ ...info, endTime: date });
  const setRecurrenceEndDate = (date: Date | undefined) =>
    setInfo({ ...info, recurrenceEndDate: date });

  const addEndTime = () => {
    if (startTime) {
      const endDate = new Date(startTime);
      endDate.setHours(startTime.getHours() + 1);
      setInfo({
        ...info,
        endTime: endDate,
      });
    }
    setAddEndFlag(true);
    if (!staticModal) setTimeout(() => endTimeInputRef.current?.click(), 10);
  };

  const removeEndTime = () => {
    setAddEndFlag(false);
    setInfo({ ...info, endTime: undefined });
  };

  const switchRecurrence = () => {
    if (isRecurring) {
      setInfo({
        ...info,
        isRecurring: false,
        frequency: undefined,
        recurrenceEndDate: undefined,
      });
      return;
    }
    const endDate = startTime ? new Date(startTime) : new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    setInfo({
      ...info,
      isRecurring: true,
      frequency: Frequency.WEEKLY,
      recurrenceEndDate: endDate,
    });
  };

  const setFrequency = (value: Frequency | undefined) => {
    if (!value) {
      setInfo({ ...info, frequency: value });
      return;
    }
    const endDate = new Date();
    switch (value) {
      case Frequency.DAILY:
        endDate.setDate(endDate.getDate() + 7);
        break;
      case Frequency.WEEKLY:
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case Frequency.BI_WEEKLY:
        endDate.setMonth(endDate.getMonth() + 2);
        break;
      case Frequency.MONTHLY:
        endDate.setFullYear(endDate.getFullYear() + 1);
        break;
      case Frequency.YEARLY:
        endDate.setFullYear(endDate.getFullYear() + 5);
        break;
      default:
        throw new Error('Invalid frequency');
    }
    setInfo({ ...info, frequency: value, recurrenceEndDate: endDate });
  };

  const handleStaticClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!staticModal) return;
    setStaticOpen((e.target as HTMLInputElement).id as 'start' | 'end' | 'recurrence');
  };

  const minStartDateTime = dayjs(minDate.current);
  const minEndDateTime = startTime ? dayjs(startTime) : minStartDateTime;
  const [staticDate, setStaticDate, staticMinDateTime] = (() => {
    switch (staticOpen) {
      case 'start':
        return [startTime, setStartTime, minStartDateTime];
      case 'end':
        return [endTime, setEndTime, minEndDateTime];
      case 'recurrence':
        return [recurrenceEndDate, setRecurrenceEndDate];
      default:
        return [];
    }
  })();

  const setDeadline = (date: Date | undefined) => setInfo({ ...info, deadline: date });

  return !staticOpen ? (
    <div className={classNames('flex flex-col gap-4', className)}>
      <Labeled label={t('component.eventDatesForm.start')}>
        <DatePicker
          slotProps={{
            textField: {
              id: 'start',
              ...(staticModal && { onClick: handleStaticClick }),
            },
          }}
          minDateTime={minStartDateTime}
          date={startTime}
          setDate={setStartTime}
        />
      </Labeled>
      {!addEndFlag ? (
        <Button className="underline px-0 py-0" onClick={addEndTime}>
          {t('component.eventDatesForm.addEnd')}
        </Button>
      ) : (
        <Labeled label={t('component.eventDatesForm.end')}>
          <div className="flex gap-4 w-full">
            <DatePicker
              ref={endTimeInputRef}
              slotProps={{
                textField: {
                  id: 'end',
                  ...(staticModal && { onClick: handleStaticClick }),
                },
              }}
              className="w-full"
              minDateTime={minEndDateTime}
              date={endTime}
              setDate={setEndTime}
            />
            <Button onClick={removeEndTime} className="rounded-full p-3" variant="tertiary">
              <HiTrash className="h-5 w-5 " />
            </Button>
          </div>
        </Labeled>
      )}
      {includeRecurrence && (
        <>
          <Labeled label={t('component.eventDatesForm.recurrence')}>
            <Toggle state={isRecurring!} handleToggle={switchRecurrence} />
          </Labeled>
          {isRecurring && (
            <>
              <Labeled
                label={t('component.eventDatesForm.frequency.title')}
                className="w-full flex flex-col gap-1">
                <DropdownSelect
                  options={Object.values(Frequency).map((type) => ({
                    label: t(`component.eventDatesForm.frequency.${type.toLowerCase()}`),
                    value: type,
                  }))}
                  value={frequency}
                  setValue={setFrequency}
                />
              </Labeled>
              <Labeled
                label={t('component.eventDatesForm.final')}
                className="w-full flex flex-col gap-1">
                <DatePicker
                  includeTime={false}
                  slotProps={{
                    textField: {
                      id: 'recurrence',
                      ...(staticModal && { onClick: handleStaticClick }),
                    },
                  }}
                  date={recurrenceEndDate}
                  setDate={setRecurrenceEndDate}
                />
              </Labeled>
            </>
          )}
        </>
      )}
      <Labeled
        label={t('component.eventDatesForm.deadline')}
        className="w-full flex flex-col gap-1">
        <DatePicker
          className="w-full"
          date={deadline}
          minDate={dayjs(new Date())}
          maxDate={dayjs(endTime ?? startTime)}
          setDate={setDeadline}
        />
      </Labeled>
    </div>
  ) : (
    <DatePicker
      static
      date={staticDate ?? undefined}
      setDate={setStaticDate ?? (() => {})}
      onClose={() => setStaticOpen(undefined)}
      minDateTime={staticMinDateTime}
    />
  );
}
