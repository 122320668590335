import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { TbDownload } from 'react-icons/tb';
import Card from '../../components/cards/Card';
import AnswerPercentageGraph from '../../components/graphs/AnswerPercentageGraph';
import AttendanceGraph from '../../components/graphs/AttendanceGraph';
import DatePicker from '../../components/forms/DatePicker';
import useEvents from '../../../hooks/business/useEvents';
import { EventParticipationAnswer, EventType, EventViewType } from '../../../types/event';
import eventService from '../../../services/eventService';
import PodiumGraph from '../../components/graphs/PodiumGraph';
import Button from '../../components/buttons/Button';
import { downloadAnalytics } from '../../../utils/fileUtils';
import { formatDateForFileName } from '../../../utils/stringUtils';
import { setEvents } from '../../../redux/slices/eventSlice';
import PrimaryOverlay from '../../components/overlays/PrimaryOverlay';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import PremiumRequiredCard from '../../components/cards/PremiumRequiredCard';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import TabButtons from '../../components/buttons/TabButtons';
import Labeled from '../../components/misc/Labeled';
import { SubscriptionType } from '../../../types/misc';

const MOCK_EVENTS = [
  {
    id: 1,
    businessId: 101,
    title: 'Networking Meetup',
    memberCount: 15,
    description: 'A local networking event for professionals.',
    location: 'San Francisco',
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: 'John',
        lastName: 'Doe',
        answer: EventParticipationAnswer.YES,
        description: 'Software engineer with 10 years of experience.',
        picture: 'https://example.com/john_doe.jpg',
      },
      {
        id: 2,
        firstName: 'Jane',
        lastName: 'Smith',
        answer: EventParticipationAnswer.MAYBE,
        description: 'Marketing specialist with a passion for branding.',
        picture: 'https://example.com/jane_smith.jpg',
      },
    ],
  },
  {
    id: 2,
    businessId: 102,
    title: 'Tech Conference',
    memberCount: 100,
    description: 'Annual tech conference for startups and innovators.',
    location: 'New York',
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: 'Alice',
        lastName: 'Johnson',
        answer: EventParticipationAnswer.YES,
        description: 'Entrepreneur and founder of a tech startup.',
        picture: 'https://example.com/alice_johnson.jpg',
      },
      {
        id: 2,
        firstName: 'Bob',
        lastName: 'Brown',
        answer: EventParticipationAnswer.NO,
        description: 'Investor looking for promising startups.',
        picture: 'https://example.com/bob_brown.jpg',
      },
      {
        id: 3,
        firstName: 'Charlie',
        lastName: 'White',
        answer: EventParticipationAnswer.PENDING,
        description: 'Product manager with a focus on user experience.',
        picture: 'https://example.com/charlie_white.jpg',
      },
    ],
  },
  {
    id: 3,
    businessId: 103,
    title: 'Marketing Workshop',
    memberCount: 30,
    description: 'A workshop for learning modern marketing strategies.',
    location: 'Los Angeles',
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: 'David',
        lastName: 'Garcia',
        answer: EventParticipationAnswer.MAYBE,
        description: 'Digital marketing expert specializing in social media.',
        picture: 'https://example.com/david_garcia.jpg',
      },
    ],
  },
  {
    id: 4,
    businessId: 104,
    title: 'Startup Pitch Night',
    memberCount: 50,
    description: 'An event for startups to pitch their ideas to investors.',
    location: 'Boston',
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: 'Eve',
        lastName: 'Martinez',
        answer: EventParticipationAnswer.YES,
        description: 'Startup founder and CEO of an AI company.',
        picture: 'https://example.com/eve_martinez.jpg',
      },
      {
        id: 2,
        firstName: 'Frank',
        lastName: 'Clark',
        answer: EventParticipationAnswer.NO,
        description: 'Venture capitalist interested in tech startups.',
        picture: 'https://example.com/frank_clark.jpg',
      },
      {
        id: 3,
        firstName: 'Grace',
        lastName: 'Hall',
        answer: EventParticipationAnswer.YES,
        description: 'Engineer working on sustainable energy solutions.',
        picture: 'https://example.com/grace_hall.jpg',
      },
    ],
  },
  {
    id: 5,
    businessId: 105,
    title: 'Design Thinking Seminar',
    memberCount: 25,
    description: 'A seminar on applying design thinking in business.',
    location: 'Chicago',
    startTime: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    type: EventType.OTHER,
    isDatePicker: false,
    participants: [
      {
        id: 1,
        firstName: 'Hannah',
        lastName: 'Lopez',
        answer: EventParticipationAnswer.YES,
        description: 'Designer with expertise in user-centered design.',
        picture: 'https://example.com/hannah_lopez.jpg',
      },
      {
        id: 2,
        firstName: 'Ian',
        lastName: 'Kim',
        answer: EventParticipationAnswer.MAYBE,
        description: 'Business strategist focused on innovation.',
        picture: 'https://example.com/ian_kim.jpg',
      },
    ],
  },
];

export default function EventReportsPage() {
  const { t } = useTranslation('', { keyPrefix: 'page.agenda.report' });
  const { t: tGeneral } = useTranslation();
  const events = useEvents(EventViewType.ALL_REGULAR);
  const dispatch = useDispatch();
  const activeSubscription = useActiveSubscription();
  const hasSubscription = activeSubscription !== SubscriptionType.FREE;
  const business = useSelectedBusiness();
  const [attendanceType, setAttendanceType] = useState<"PER_USER" | "PER_EVENT">("PER_EVENT");

  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(Date.now() - 1000 * 60 * 60 * 24 * 90), // today - 90 days
  );
  const [endDate, setEndDate] = useState<Date | undefined>(new Date(Date.now()));

  useEffect(() => {
    if (!business || !business.businessId) return;

    if (!hasSubscription) {
      dispatch(setEvents({ events: MOCK_EVENTS as any, businessId: business.businessId }));
      return;
    }

    if (!startDate || !endDate) return;

    eventService.getEventAnalytics(startDate, endDate);
  }, [startDate, endDate, hasSubscription, business]);

  console.log(events);

  const handleDownload = () => {
    if (!startDate || !endDate) return;

    downloadAnalytics(
      events,
      `${t('title')}_${tGeneral('general.from')}_${formatDateForFileName(startDate)}_${tGeneral('general.to')}_${formatDateForFileName(endDate)}`,
    );
  };

  return (
    <main className={classNames('flex flex-col gap-4 relative overflow-x-hidden', {
      'overflow-hidden h-[80vh]': !hasSubscription,
    })}>
      {!hasSubscription && (
        <PrimaryOverlay>
          <PremiumRequiredCard />
        </PrimaryOverlay>
      )}
      <div className="">
        <h1 className="text-2xl font-serif">{t('title')}</h1>
        <p className="text-gray-500 w-1/3 max-md:w-full">{t('subtitle')}</p>
      </div>
      <div className="flex flex-col bg-secondary-200 bg-opacity-50 px-8 py-3 mt-4 rounded-xl">
        <div className="flex justify-between items-center max-md:flex-col-reverse">
          <h3 className="font-semibold">{t('currentEventsPerPeriod')}</h3>
          <Button onClick={handleDownload}>
            {t('exportButton')}
            <TbDownload />
          </Button>
        </div>
        <div className="flex justify-between items-center max-md:flex-col gap-2">
          <p
            className="text-xl font-semibold">{events.length} {t(`event${events.length === 1 ? '' : 's'}`).toLowerCase()}</p>
          <div className="flex gap-4 [&_input]:bg-primary-900 [&_input]:text-secondary-50 [&_.MuiInputBase-root]:pr-0">
            <Labeled label={tGeneral('general.from')}>
              <DatePicker date={startDate} setDate={setStartDate} includeTime={false} />
            </Labeled>
            <Labeled label={tGeneral('general.to')}>
              <DatePicker date={endDate} setDate={setEndDate} includeTime={false} />
            </Labeled>
          </div>
        </div>
      </div>
      {events.length === 0 ? (
        <div className="px-8 py-2 mt-4 bg-secondary rounded-lg self-center">
          <p className="flex gap-2 items-center">
            <FaInfoCircle />
            {t('noEvents')}
          </p>
        </div>
      ) : (
        <>
          <section className="flex gap-4 max-md:flex-col">
            <AnswerPercentageGraph events={events} />
            <Card className="bg-secondary-200 items-start bg-opacity-50 justify-between flex-[2]">
              <h3 className="font-serif text-xl">{t('podiumTitle')}</h3>
              <PodiumGraph events={events} />
            </Card>
          </section>
          <Card className="bg-secondary-200 w-full items-start bg-opacity-50 justify-between">
            <div className="flex justify-between items-center w-full max-sm:flex-col gap-2">
              <h3 className="font-serif text-xl">
                {attendanceType === 'PER_EVENT' ? t('answersPerEvent') : t('answersPerUser')}
              </h3>
              <TabButtons options={[
                { label: t('perEvent'), value: 'PER_EVENT' as const },
                { label: t('perUser'), value: 'PER_USER' as const },
              ]} onTabChange={setAttendanceType} />
            </div>
            <AttendanceGraph events={events} type={attendanceType} />
          </Card>
        </>
      )}
    </main>
  );
}

