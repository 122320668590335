import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import { CommunityType, MomentType } from '../../../types/business';
import FaqDropdown from '../../components/misc/FaqDropdown';
import VerticalStepIndicator from '../../components/misc/VerticalStepIndicator';
import AppearInViewDiv from '../../components/misc/AppearInViewDiv';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { APPLICATION_VERSION, isMoments } from '../../../constants';
import useLanguage from '../../../hooks/account/useLanguage';
import useLandingPageData from '../../../hooks/business/useLandingPageData';
import Button from '../../components/buttons/Button';

export default function LandingPageRedirect(): JSX.Element {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login');
  }, []);

  return <div className="min-h-[100vh]" />;
}

/**
 * @deprecated Landing page is not used anymore. Instead, a login form is displayed
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function LandingPage(): JSX.Element {
  const { t } = useBusinessTranslation();
  const navigate = useNavigate();
  const lang = useLanguage();
  const { faq, descriptions, steps } = useLandingPageData();

  const [stepIndex, setStepIndex] = React.useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const timeOutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setStepIndex((prev) => (prev + 1) % steps.length);
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeOutRef.current);
    };
  }, []);

  const setIndex = (index: number) => {
    setStepIndex(index);
    clearInterval(intervalRef.current);
    clearTimeout(timeOutRef.current);
    timeOutRef.current = setTimeout(() => {
      intervalRef.current = setInterval(() => {
        setStepIndex((prev) => (prev + 1) % 3);
      }, 3000);
    }, 6000);
  };

  const renderDescription = (type: MomentType, index: number) => {
    const description = descriptions[type as keyof typeof descriptions];
    if (!description) return null;
    return (
      <AppearInViewDiv
        className={`w-full full-width px-5 ${
          index % 2 === 0 ? 'bg-secondary-50' : 'bg-secondary-200'
        }`}>
        <div
          className={classNames(
            'max-w-7xl flex flex-col justify-between w-full mx-auto gap-12 py-20 items-center md:px-10 ',
            {
              'md:flex-row-reverse': index % 2 === 0,
              'md:flex-row': index % 2 !== 0,
            },
          )}>
          <div className="bg-primary-300 rounded-lg px-24 py-10 flex items-center justify-center w-full">
            <img
              alt={type}
              className="w-[90%]"
              src={`${process.env.PUBLIC_URL}/assets/onboarding/${type.toLowerCase()}.svg`}
            />
          </div>
          <div className="w-full flex flex-col">
            <h3 className="text-primary text-2xl font-bold">
              {t(`page.onboarding.type.${type.toLowerCase()}.name`)}
            </h3>
            <h2 className="text-4xl font-medium">
              {t(`page.landing.usecase.${type.toLowerCase()}`)}
            </h2>
            <ul className="my-4">
              {description.map((d) => (
                <li className="flex gap-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/landing/check.svg`}
                    alt="check"
                    className="w-[15px] h-[15px] mt-[5px]"
                  />
                  {d}
                </li>
              ))}
            </ul>
            <Button onClick={() => navigate(`/onboarding?type=${type}`)} variant="primary">
              {t('page.landing.start')}
            </Button>
          </div>
        </div>
      </AppearInViewDiv>
    );
  };
  return (
    <div className="w-full h-full min-h-[calc(100vh-202px)] moments-landing flex flex-col px-5 items-center overflow-x-hidden">
      {/* Header */}
      <AppearInViewDiv className="max-w-7xl flex justify-between w-full mx-auto sm:gap-[10%] py-12 sm:py-24 items-center flex-col sm:flex-row gap-10">
        <div className="flex flex-col w-full gap-4">
          <h1 className="text-6xl font-bold">{t('page.landing.title')}</h1>
          <p>{t('page.landing.subtitle')}</p>
          <Button onClick={() => navigate(`/onboarding`)} variant="primary">
            {t('page.landing.start')}
          </Button>
        </div>
        <div className="rounded-[20px] hidden w-full h-fit sm:flex items-center justify-end">
          <img
            alt="Not found"
            src={`${
              process.env.PUBLIC_URL
            }/assets/landing/${APPLICATION_VERSION.toLowerCase()}/dashboard_screen_${lang}.png`}
            className="object-fit w-full"
          />
        </div>
      </AppearInViewDiv>

      {/* Vera Mission */}
      <div className="w-full max-w-7xl mx-auto flex flex-col mb-12 sm:mb-20">
        <h3 className="text-primary text-xl font-bold mx-auto">{t('page.landing.why.title')}</h3>
        <div className="flex flex-col md:flex-row gap-8 mt-8">
          <div className="w-full flex flex-col text-center">
            <div className="h-72 flex items-center justify-center rounded-lg bg-secondary p-8">
              <img
                alt="secure"
                id="secure"
                src={`${process.env.PUBLIC_URL}/assets/figures/phone.svg`}
                className="h-full object-fit "
              />
            </div>
            <h3 className="font-bold text-lg mt-2">{t('page.landing.why.contact.title')}</h3>
            <p className="text-sm">{t('page.landing.why.contact.description')}</p>
          </div>
          <div className="w-full flex flex-col text-center">
            <div className="h-72 flex items-center justify-center rounded-lg bg-secondary p-8">
              <img
                alt="secure"
                src={`${process.env.PUBLIC_URL}/assets/figures/heart.svg`}
                className="h-full object-fit"
              />
            </div>
            <h3 className="font-bold text-lg mt-2">{t('page.landing.why.easy.title')}</h3>
            <p className="text-sm">{t('page.landing.why.easy.description')}</p>
          </div>

          <div className="w-full flex flex-col text-center">
            <div className=" h-72 flex items-center justify-center rounded-lg bg-secondary p-8">
              <img
                alt="secure"
                src={`${process.env.PUBLIC_URL}/assets/figures/house.svg`}
                className="h-full object-fit"
              />
            </div>
            <h3 className="font-bold text-lg mt-2">{t('page.landing.why.privacy.title')}</h3>
            <p className="text-sm">{t('page.landing.why.privacy.description')}</p>
          </div>
        </div>
      </div>

      {/* Use case description */}
      <div className="w-full max-w-5xl mx-auto flex flex-col">
        <div className="flex flex-col text-center max-w-3xl mx-auto">
          <h3 className="text-primary text-xl font-bold">{t('page.landing.steps.subtitle')}</h3>
          <h2 className="text-4xl font-medium mb-4">{t('page.landing.steps.title')}</h2>
          <p>{t('page.landing.steps.description')}</p>
        </div>

        <div className="flex flex-col-reverse sm:flex-row gap-10 my-10 items-center">
          <div className="bg-secondary-200 rounded-lg py-10 flex items-center justify-center w-full">
            <img
              alt="balloon_heart"
              className="w-[90%]"
              src={`${
                process.env.PUBLIC_URL
              }/assets/landing/${APPLICATION_VERSION.toLowerCase()}/steps/${
                steps[stepIndex]
              }_${lang}.png`}
            />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <VerticalStepIndicator stepIndex={stepIndex} setStepIndex={setIndex} className="ml-2">
              {steps.map((step) => (
                <VerticalStepIndicator.Item key={step}>
                  <p className="font-bold text-lg">{t(`page.landing.steps.${step}.title`)}</p>
                  <p className="text-sm">{t(`page.landing.steps.${step}.description`)}</p>
                </VerticalStepIndicator.Item>
              ))}
            </VerticalStepIndicator>
            <Button onClick={() => navigate('/onboarding')} variant="primary">
              {t('page.landing.start')}
            </Button>
          </div>
        </div>
      </div>

      {/* Indivual panels */}
      <div className="flex flex-col text-center max-w-3xl mx-auto z-40 -mb-10 sm:mt-10">
        <h3 className="text-primary text-xl font-bold">{t('page.landing.usecase.subtitle')}</h3>
        <h2 className="text-4xl font-medium mb-4">{t('page.landing.usecase.title')}</h2>
      </div>
      {Object.values(
        isMoments
          ? MomentType
          : _.omit(CommunityType, [
              CommunityType.FAMILY,
              CommunityType.FRIENDS,
              CommunityType.WORK,
            ]),
      )
        .filter((v) => !!descriptions[v as keyof typeof descriptions])
        .map(renderDescription)}

      {/* FAQ */}
      <div className="max-w-7xl pb-20 sm:pt-10 flex mx-auto gap-10 flex-col md:flex-row">
        <div className="w-full">
          <h3 className="text-primary text-xl font-bold">{t('page.landing.faq.subtitle')}</h3>
          <h2 className="text-4xl font-medium mb-4">{t('page.landing.faq.title')}</h2>
          <p>{t('page.landing.faq.description')}</p>
        </div>
        <div className="w-full flex flex-col gap-4">
          {faq.map((f) => (
            <FaqDropdown
              key={f}
              question={t(`page.landing.faq.${f}.question`)}
              answer={t(`page.landing.faq.${f}.answer`)}
            />
          ))}
          <p className="mx-auto mt-4">
            {t('page.landing.faq.email')}
            <span
              className="text-primary cursor-pointer underline underline-offset-4"
              onClick={() => window.open('mailto:?to=support@veraconnect.nl', '_blank')}>
              support@veraconnect.nl
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
