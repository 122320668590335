import React from 'react';
import { useDispatch } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { FaChevronDown, FaPlus } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import HoverButton from '../../../buttons/HoverButton';
import { isMoments } from '../../../../../constants';
import useSelectedBusiness from '../../../../../hooks/business/useSelectedBusiness';
import useBusinesses from '../../../../../hooks/business/useBusinesses';
import { BUTTON_STYLE, LABEL_STYLE } from './style';
import { businessTypeIcons } from '../../../../../utils/iconUtils';
import Icon from '../../../icons/Icon';
import {
  setSelectedBusiness,
  wipeSelectedBusiness,
} from '../../../../../redux/slices/applicationSlice';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';

export default function BusinessButton() {
  const business = useSelectedBusiness();
  const businesses = useBusinesses();
  const dispatch = useDispatch();
  const { t } = useBusinessTranslation();

  if (!business) return null;

  const handleBusinessSelect = (businessId: number) => {
    dispatch(wipeSelectedBusiness());
    dispatch(setSelectedBusiness(businessId));
  };

  const label = isMoments ? 'Moment' : 'Community';
  return (
    <HoverButton>
      <HoverButton.Button>
        <div className=" flex justify-between text-[18px] items-center px-3 py-[8px] gap-2 w-fit rounded-full group-[.expanded]:bg-opacity-20 group-[.expanded]:bg-primary-300 hover:bg-opacity-20 hover:bg-primary-300 transition-all">
          <div className="flex gap-2">
            <span className="font-serif">{label}:</span>
            <span className="text-primary font-medium">{business.name}</span>
          </div>
          <FaChevronDown className="w-4 h-4 text-primary" />
        </div>
      </HoverButton.Button>
      <HoverButton.Div className="bg-primary-900 min-w-[250px] border border-primary-300 w-full p-3 rounded-[28px] mt-2 gap-[10px] flex flex-col ">
        <div className="font-serif w-full text-secondary-50 text-xl">{label}:</div>
        <div className="w-full border border-primary-300 " />
        {(businesses ?? []).map((b) => (
          <div
            key={b.businessId}
            onClick={() => handleBusinessSelect(b.businessId!)}
            className={twMerge(
              BUTTON_STYLE,
              'py-2 px-3 leading-[19px] items-center cursor-pointer justify-between',
              classNames({
                'bg-primary-300 hover:bg-opacity-100 text-primary-900':
                  business.businessId === b.businessId,
              }),
            )}>
            <div className="flex gap-2 items-center w-full">
              <Icon icon={businessTypeIcons[b.type]} className="w-5 h-5 flex-shrink-0" />
              <p
                className={twMerge(
                  LABEL_STYLE,
                  'text-ellipsis font-medium overflow-hidden w-full pr-2',
                  business.businessId === b.businessId && 'text-primary-900',
                )}>
                {b.name}
              </p>
            </div>
            {business.businessId === b.businessId && (
              <FaCheck className="w-3 h-3 text-primary-900 flex-shrink-0" />
            )}
          </div>
        ))}
        <div className="w-full border border-primary-300 " />
        <div className="w-full flex flex-col flex-shrink-0">
          <Link to="/onboarding" className={twMerge(BUTTON_STYLE, 'py-2 px-3 text-sm gap-2')}>
            <FaPlus className={twMerge(LABEL_STYLE, 'w-5 h-5 text-primary-300 ')} />
            <p
              className={twMerge(
                LABEL_STYLE,
                'text-primary-300 font-medium text-base leading-[19px]',
              )}>
              {t('page.businessOverview.new')}
            </p>
          </Link>
        </div>
      </HoverButton.Div>
    </HoverButton>
  );
}
