/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import WarningModal from './WarningModal';
import ConnectionWithIcons from '../misc/ConnectionWithIcons';
import LocalContactModal from './LocalContactModal';
import TagDisplay from '../misc/TagDisplay';
import ModalLayout from '../layouts/ModalLayout';
import {
  deleteConnections,
  getProfilePictureUrl,
  updateNote,
} from '../../../utils/connectionUtils';
import ProfilePicture from '../misc/ProfilePicture';
import Button from '../buttons/Button';
import ConnectionStatus from '../misc/ConnectionStatus';

interface UserInfoModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  connection: Connection | undefined;
}

export default function UserInfoModal({
  open,
  setOpen,
  connection,
}: UserInfoModalProps): JSX.Element {
  const [profilePicture, setProfilePicture] = useState<string | undefined>(undefined);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [localContactDialog, setLocalContactDialog] = useState<boolean>(false);
  const [note, setNote] = useState<string>();

  const { t } = useTranslation();

  const handleDelete = async () => {
    await deleteConnections([{ id: connection!.id, type: connection!.type }]);
  };

  const handleNoteSave = async () => {
    await updateNote(note!, { id: connection!.id, type: connection!.type });
    toast.success(t('toast.success.savedNote'));
  };

  useEffect(() => {
    if (!open) return;
    setProfilePicture(undefined);
    setNote(connection?.note!);
  }, [open]);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (open && connection) {
        getProfilePictureUrl(connection).then(setProfilePicture);
      }
    };
    fetchProfilePicture();
  }, [open]);

  const getTypeText = () => {
    if (connection!.type === ConnectionType.B2C_CONNECTION) {
      return t('contact.connection');
    } else if (connection!.type === ConnectionType.LOCAL_CONTACT) {
      return t('contact.addedSelf');
    }
    return '';
  };

  return (
    <>
      <ModalLayout
        open={open}
        setOpen={setOpen}
        closeButton
        className="bg-secondary-50 min-w-[300px] w-[45rem] p-8  z-50 rounded-2xl relative">
        {/* Header */}
        {connection && (
          <>
            <div className="flex w-full pb-8 border-b border-primary-900">
              <div className="rounded-xl h-fit w-28 mr-6 relative">
                <ProfilePicture
                  picture={profilePicture}
                  alias={`${connection.fields.FIRST_NAME}+${connection.fields.LAST_NAME}`}
                  className="w-28 h-28 text-[40px]"
                />
                <ConnectionStatus
                  connection={connection}
                  className="absolute -bottom-3 -right-3 border-secondary-50 border"
                />
              </div>

              {/* Header buttons */}
              <div className="flex flex-col">
                <h1 className="font-medium mt-1 text-3xl">{`${connection.fields.FIRST_NAME} ${connection.fields.LAST_NAME}`}</h1>
                <p className="text-gray-500">{getTypeText()}</p>
                <div className="flex">
                  {/* Edit local contact button */}
                  {connection.type === ConnectionType.LOCAL_CONTACT && (
                    <Button
                      variant="secondary"
                      className="mt-4 "
                      onClick={() => {
                        setOpen(false);
                        setLocalContactDialog(true);
                      }}>
                      {' '}
                      {t('component.actions.connection.edit')}
                      <FaPencilAlt className="h-5 w-5" />
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {/* Information only visible if there are more fields than just the name */}
            {Object.values(connection.fields).filter((x) => x).length > 1 && (
              <div className="">
                <p className="font-medium text-xl mt-6 mb-4">{t('general.information')}</p>
                <ConnectionWithIcons connection={connection} />
              </div>
            )}

            {/* Notes */}
            <div className="flex flex-col gap-2 mt-4">
              <p className="font-medium text-xl">{t('general.note')}</p>
              <textarea
                data-testid="postcard-text"
                className="rounded-lg h-32 resize-none w-full placeholder-gray-300 mb-2"
                placeholder="Schrijf hier je opmerkingen..."
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />
              <Button
                variant="primary"
                onClick={handleNoteSave}
                disabled={note === connection.note || (!note && !connection.note)}>
                {t('general.save')}
              </Button>
            </div>

            {/* Tags */}
            <div className="flex justify-between relative h-fit">
              <div className="flex flex-col w-4/6">
                <p className="font-medium text-xl mt-6 mb-2">{t('general.tags')}</p>
                <TagDisplay connection={connection} withHover />
              </div>

              {/* Delete contact button */}
              <Button
                onClick={() => {
                  setOpen(false);
                  setDeleteDialog(true);
                }}
                className="underline px-0 absolute right-0 bottom-0 group hover:text-opacity-80">
                {t('component.actions.connection.deleteContact')}
                <HiTrash className="h-5 w-5" />
              </Button>
            </div>
          </>
        )}
      </ModalLayout>
      <WarningModal
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClose={handleDelete}
        title={
          connection?.type === ConnectionType.LOCAL_CONTACT ||
          connection?.type === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.title')
            : t('warning.deleteConnection.title')
        }
        saveClassName="bg-error"
        description={
          connection?.type === ConnectionType.LOCAL_CONTACT ||
          connection?.type === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.description')
            : t('warning.deleteConnection.description')
        }
        button={
          connection?.type === ConnectionType.LOCAL_CONTACT ||
          connection?.type === ConnectionType.PERSONAL_MOMENT_CONNECTION
            ? t('warning.deleteLocalContact.title')
            : t('warning.deleteConnection.title')
        }
      />
      <LocalContactModal
        open={localContactDialog}
        setOpen={setLocalContactDialog}
        connectionId={connection?.id!}
      />
    </>
  );
}
