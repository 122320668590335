import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Admin } from '../../types/business';

export default function useAdmins(): Admin[] {
  const { admins, invites } = useSelector((state: RootState) => state.admins);

  const pendingAdmins: Admin[] = invites.map((invite) => ({
    role: invite.role,
    userId: invite.userId,
    display: invite.display,
    picture: invite.picture || '',
    pending: true,
    inviteId: invite.inviteId,
  }));

  const existingAdmins: Admin[] = admins.map((admin) => ({ ...admin, pending: false }));

  return existingAdmins.concat(pendingAdmins).sort((a, b) => a.display.localeCompare(b.display));
}
