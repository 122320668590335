import React, { useLayoutEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BusinessInvite, CustomFieldType } from '../../../services/model/inviteService.model';
import { LocalContactInfo } from '../../../services/model/localContactService.model';
import {
  createEmptyBusinessInvite,
  createEmptyField,
  createEmptyLocalContact,
} from '../../../utils/formatUtils';
import localContactService from '../../../services/localContactService';
import inviteService from '../../../services/inviteService';
import ModalLayout from '../layouts/ModalLayout';
import NameInput from '../forms/profile/NameInput';
import { sortProfileFields } from '../../../utils/sortUtils';
import ProfileFieldForm from '../forms/ProfileFieldForm';
import CustomFieldsForm from '../forms/CustomFieldsForm';
import CommunicationNameInput from '../forms/profile/CommunicationNameInput';
import { isEmptyField, isValidLocalContact } from '../../../utils/validationUtils';
import CancelSaveButton from '../misc/CancelSaveButton';
import ImageInput from '../forms/ImageInput';
import IconBadge from '../icons/IconBadge';
import { isMoments } from '../../../constants';
import { ProfileDataType, ProfileField } from '../../../types/Profile';

interface LocalContactModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  connectionId?: string;
}

export default function LocalContactModal({ open, setOpen, connectionId }: LocalContactModalProps) {
  const { t } = useTranslation();

  const [invite, setInvite] = useState<BusinessInvite>(createEmptyBusinessInvite());
  const [localContact, setLocalContact] = useState<LocalContactInfo>(createEmptyLocalContact());
  const [image, setImage] = React.useState<string>();
  const [imageAction, setImageAction] = React.useState<'UPLOAD' | 'DELETE'>();
  const [validate, setValidate] = useState(false);

  useLayoutEffect(() => {
    const fetchConnection = async () => {
      if (!open) return;
      const fetchedInvite = await inviteService.fetchInvite();

      if (connectionId) {
        const [contactInfo, profilePicture] = await Promise.all([
          localContactService.fetchLocalContact(connectionId).catch(() => undefined),
          localContactService.getProfilePictureAsFile(connectionId).catch(() => undefined),
          inviteService.fetchInvite(),
        ]);
        setInvite(fetchedInvite);

        if (contactInfo)
          setLocalContact({
            ...contactInfo,
            receivedData: [...fetchedInvite.mandatoryFields, ...fetchedInvite.optionalFields].map(
              (f) =>
                contactInfo.receivedData.find(
                  (d) => d.dataType === f || d.social?.socialType === f,
                ) || createEmptyField(f),
            ),
          });
        if (profilePicture) setImage(window.URL.createObjectURL(profilePicture));
        else setImage(undefined);
      } else {
        setInvite(fetchedInvite);
        setImage(undefined);
        setValidate(false);
        setImageAction('UPLOAD');
        setLocalContact({
          ...createEmptyLocalContact(),
          receivedData: [
            ...(fetchedInvite.mandatoryFields || []),
            ...(fetchedInvite.optionalFields || []),
          ].map(createEmptyField),
        });
      }
    };

    fetchConnection();
  }, [connectionId, open]);

  const handleSave = async () => {
    setValidate(true);
    if (!isValidLocalContact(localContact, invite)) return;
    let contactId = connectionId;
    if (connectionId) {
      await localContactService.updateLocalContact(
        connectionId,
        localContact.firstName,
        localContact.lastName,
        localContact.note,
        localContact.receivedData,
        localContact.customFieldResponses,
      );
    } else {
      const { id } = await localContactService
        .createLocalContacts([
          {
            ...localContact,
            receivedData: localContact.receivedData.filter((f) => !isEmptyField(f)),
          },
        ])
        .then((res) => res?.[0]);
      contactId = id;
    }

    if (imageAction === 'UPLOAD' && image)
      await localContactService.uploadProfilePicture(
        contactId!,
        await fetch(image!).then((res) => res.blob() as Promise<File>),
      );
    else if (imageAction === 'DELETE' && connectionId)
      await localContactService.deleteProfilePicture(contactId!);

    setOpen(false);
  };

  const communicationName = invite.customFields.find(
    (f) => f.type === CustomFieldType.COMMUNICATION_NAME,
  );

  const handleFieldChangeByType = (newField: ProfileField) => {
    const receivedData =
      newField.dataType === ProfileDataType.SOCIAL
        ? localContact.receivedData.map((oldField) =>
            oldField.social?.socialType === newField.social?.socialType ? newField : oldField,
          )
        : localContact.receivedData.map((oldField) =>
            oldField.dataType === newField.dataType ? newField : oldField,
          );
    setLocalContact({ ...localContact, receivedData });
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-secondary-50  w-full my-8 mx-6 sm:max-w-[700px] z-50 rounded-[20px] p-8">
      <div className="flex border-b border-secondary-200">
        <IconBadge icon={FaUser} />

        <div className="flex flex-col ml-4 mb-4 w-full">
          <h2 className="text-2xl font-serif">
            {connectionId
              ? t('component.modal.localContact.edit.title')
              : t('component.modal.localContact.create.title')}
          </h2>
          <p>
            {connectionId
              ? t('component.modal.localContact.edit.subtitle')
              : t('component.modal.localContact.create.subtitle')}
          </p>
        </div>
      </div>
      <div className="mt-4 gap-4 flex flex-col w-full">
        <ImageInput
          className="w-32 h-32"
          iconClassName="w-14 h-14"
          image={image}
          setImage={setImage}
          setImageAction={setImageAction}
        />

        <NameInput
          alias={`${localContact.firstName || ''}+${localContact.lastName || ''}`}
          validate={validate}
          setAlias={(s) => {
            const [firstName, lastName] = s.split('+');
            setLocalContact({ ...localContact, firstName, lastName });
          }}
        />

        {communicationName && isMoments && (
          <CommunicationNameInput
            name={localContact.customFieldResponses[communicationName?.customFieldId!] || ''}
            setName={(newField) =>
              setLocalContact({
                ...localContact,
                customFieldResponses: {
                  ...localContact.customFieldResponses,
                  [communicationName?.customFieldId!]: newField,
                },
              })
            }
          />
        )}

        {sortProfileFields(localContact.receivedData).map((f) => (
          <ProfileFieldForm
            field={f}
            canBeEmpty
            validate={validate}
            setField={handleFieldChangeByType}
          />
        ))}

        <CustomFieldsForm
          fields={invite.customFields || []}
          response={localContact.customFieldResponses}
          setResponse={(newResponse) =>
            setLocalContact({ ...localContact, customFieldResponses: newResponse })
          }
        />

        {/* Description */}
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium mb-2">{t('component.modal.localContact.noteTitle')}</p>
          <textarea
            className="rounded-lg h-32 resize-none w-full placeholder-secondary-200 mb-2"
            placeholder="Schrijf hier je opmerkingen..."
            onChange={(e) => setLocalContact({ ...localContact, note: e.target.value })}
            value={localContact.note}
          />
        </div>

        {/* Triggers rerender of table when assigning so can only be added once we deal with rerender issue */}
        {/* {connection && <TagDisplay connection={connection} withHover={!isMobile} />} */}
      </div>
      <div className=" flex flex-col flex-1 gap-2 items-end w-full">
        <p
          className={classNames('justify-end w-fit text-sm text-error', {
            invisible: !validate || isValidLocalContact(localContact, invite),
          })}>
          {t('component.modal.localContact.invalid')}
        </p>

        <CancelSaveButton onCancel={() => setOpen(false)} onSave={handleSave} />
      </div>
    </ModalLayout>
  );
}
