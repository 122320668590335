import React, { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

type TabButtonsProps<A, B> = {
  options: [{ label: string, value: A }, { label: string, value: B }];
  initialState?: A | B;
  onTabChange?: (tab: A | B) => void;
  buttonClassName?: string;
  buttonSelectedClassName?: string;
  containerClassName?: string;
  highlightClassName?: string;
}

export default function TabButtons<A, B>({
  initialState,
  options,
  onTabChange,
  buttonSelectedClassName,
  highlightClassName,
  containerClassName,
  buttonClassName,
}: TabButtonsProps<A, B>): JSX.Element {
  const [activeTab, setActiveTab] = React.useState<A | B>(initialState || options[0].value);

  useEffect(() => {
    onTabChange?.(activeTab);
  }, [activeTab]);

  return (
    <div className={twMerge('flex bg-secondary-50 relative p-2 rounded-xl', containerClassName)}>
      <TabButton className={activeTab === options[0].value ? buttonSelectedClassName : buttonClassName}
                 isSelected={activeTab === options[0].value}
                 onClick={() => setActiveTab(options[0].value)}>{options[0].label}</TabButton>
      <TabButton className={activeTab === options[1].value ? buttonSelectedClassName : buttonClassName}
                 isSelected={activeTab === options[1].value}
                 onClick={() => setActiveTab(options[1].value)}>{options[1].label}</TabButton>
      <div
        className={twMerge(`absolute rounded-xl bg-secondary-200 px-6 py-2 h-[calc(100%-1rem)] w-[calc(50%-0.5rem)] transition-all ${activeTab === options[0].value ? 'translate-x-0' : 'translate-x-full'}`, highlightClassName)} />
    </div>
  );
}

type TabButtonProps = {
  className?: string;
  isSelected: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function TabButton({ className, isSelected, ...props }: TabButtonProps): JSX.Element {
  return (
    <button
      className={twMerge(`w-max z-10 px-6 py-2 flex-1 whitespace-nowrap rounded-xl ${isSelected ? 'font-semibold' : 'opacity-60'}`, className)}
      type="button"
      {...props}
    />
  );
}
