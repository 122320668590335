import React, { useEffect } from 'react';
import { Address, ProfileDataType } from '../../../types/Profile';
import userService from '../../../services/userService';
import useProfileFields from '../../../hooks/account/useProfileFields';

interface AddressPickerProps {
  selectedAddress: Address | null;
  setSelectedAddress: (address: Address | null) => void;
}
export default function AddressPicker({
  selectedAddress,
  setSelectedAddress,
}: AddressPickerProps): JSX.Element {
  const addresses = useProfileFields()
    .filter((field) => field.dataType === ProfileDataType.ADDRESS)
    .map((f) => f.address) as Address[];

  useEffect(() => {
    userService.getUserData();
  }, []);

  const handleAddressChange = (address: Address): void => {
    if (address === selectedAddress) setSelectedAddress(null);
    else setSelectedAddress(address);
  };

  const getAddressColor = (address: Address): string => {
    if (address === selectedAddress) return 'bg-secondary';
    return '!bg-secondary-200';
  };

  return (
    <div className="grid grid-cols-2 gap-x-8 gap-y-2">
      {addresses.map(
        (address: Address, index: number): JSX.Element => (
          <div
            key={index}
            data-testid={`address-${index}`}
            className={`rounded-[20px]  relative flex min-w-[220px] ${getAddressColor(
              address,
            )} cursor-pointer w-full mt-4 p-4`}
            onClick={(): void => handleAddressChange(address)}>
            <input
              type="checkbox"
              className={getAddressColor(address)}
              checked={selectedAddress === address}
            />

            <div className="px-2">
              <p>{`${address.street} ${address.houseNumber}${
                address.houseNumberAddition || ''
              }`}</p>
              <p>{`${address.postCode} ${address.city}`}</p>
              <p>{address.country}</p>
            </div>
          </div>
        ),
      )}
    </div>
  );
}
