import { ApplicationVersion } from './types/misc';

export const APPLICATION_VERSION =
  (process.env.REACT_APP_APPLICATION_VERSION as ApplicationVersion) || ApplicationVersion.MOMENTS;

export const isMoments = APPLICATION_VERSION === ApplicationVersion.MOMENTS;
export const isCommunity = APPLICATION_VERSION === ApplicationVersion.COMMUNITY;

// Color pallette
export const COLOR_PRIMARY = '#0A1B89';
export const COLOR_PRIMARY_300 = '#92A2EC';
export const COLOR_PRIMARY_900 = '#081647';
export const COLOR_SECONDARY = '#F19E7A';
export const COLOR_SECONDARY_50 = '#FCEEEB';
export const COLOR_SECONDARY_200 = '#EDD5C9';
export const COLOR_SECONDARY_600 = '#f17c51';
export const COLOR_ERROR = '#BB0000';
export const COLOR_DANGER = '#D8273E';

export const DURATIONS_MS = {
  MINUTE: 60 * 1000,
  FIVE_MINUTES: 5 * 60 * 1000,
  FIFTEEN_MINUTES: 15 * 60 * 1000,
  THIRTY_MINUTES: 30 * 60 * 1000,
  ONE_HOUR: 60 * 60 * 1000,
  ONE_DAY: 24 * 60 * 60 * 1000,
  ONE_WEEK: 7 * 24 * 60 * 60 * 1000,
  ONE_MONTH: 30 * 24 * 60 * 60 * 1000,
  ONE_YEAR: 365 * 24 * 60 * 60 * 1000,
};

export const stripeAppearance = {
  theme: 'flat',

  variables: {
    colorPrimary: COLOR_PRIMARY_900,
    colorBackground: COLOR_SECONDARY_200,
    colorText: COLOR_PRIMARY_900,
    colorDanger: COLOR_SECONDARY,
  },
};

export const API_URL =
  process.env.REACT_APP_API_URL ??
  (process.env.NODE_ENV === 'development'
    ? 'http://localhost:3500'
    : 'https://veraconnect.app/api');

export const CONSUMER_URL = process.env.REACT_APP_CONSUMER_URL || 'https://localhost:3333';

export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';
