import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useDeleteParams(...paramNames: string[]): void {
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (paramNames.length > 0) {
      const updatedParams = new URLSearchParams(params);

      paramNames.forEach((paramName): void => {
        if (updatedParams.has(paramName)) {
          updatedParams.delete(paramName);
        }
      });

      setParams(updatedParams);
    }
  }, []);
}
