import React from 'react';
import ModalLayout from '../layouts/ModalLayout';
import CancelSaveButton from '../misc/CancelSaveButton';

export interface SimpleWarningModalProps {
  open: boolean;
  title: string;
  description: string;
  button: string;
  onClose: () => void;
  saveClassName?: string;
  cancelClassName?: string;
}

interface WarningModalProps {
  open: boolean;
  setOpen: (bool: boolean) => void;
  onClose?: () => void;
  title: string;
  description: string;
  button?: string;
  saveClassName?: string;
  cancelClassName?: string;
}
export default function WarningModal({
  open,
  setOpen,
  onClose = () => {},
  title,
  description,
  button,
  saveClassName,
  cancelClassName,
}: WarningModalProps): JSX.Element {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="relative transform overflow-hidden rounded-[20px] px-6 py-4 bg-secondary-50 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
      <div className="flex flex-col mb-4 gap-2">
        <h3 className="text-xl font-semibold">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <div className="justify-end flex">
        <CancelSaveButton
          onCancel={() => setOpen(false)}
          onSave={() => {
            onClose();
            setOpen(false);
          }}
          buttonText={button}
          saveClassName={saveClassName}
          cancelClassName={cancelClassName}
        />
      </div>
    </ModalLayout>
  );
}
