import useSelectedBusiness from './useSelectedBusiness';
import { SubscriptionType } from '../../types/misc';

export default function useActiveSubscription(): SubscriptionType {
  const business = useSelectedBusiness();

  return (business?.trialEndDate && new Date(business.trialEndDate) > new Date())
    ? SubscriptionType.PRO // If the users are given a trial period from the admins, they are considered as PRO users
    : business?.subscription?.subscriptionType ?? SubscriptionType.FREE; // Otherwise, the subscription type is determined by the subscription object
}
