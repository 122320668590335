import { format } from 'date-fns';
import _ from 'lodash';
import { enUS, nl } from 'date-fns/locale';
import {
  AttendantData,
  DateOption,
  Event,
  EventParticipationAnswer,
  EventNotificationType,
  NotificationSettings,
  CreateEventModel,
  EventCustomField,
  EventParticipant,
} from '../types/event';
import { capitalizeWords, formatDate as formatSingleDate } from './stringUtils';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import Language from '../types/language';
import dateUtils from './dateUtils';

const ANSWER_WEIGHTS = {
  [EventParticipationAnswer.PENDING]: 0,
  [EventParticipationAnswer.NO]: 0,
  [EventParticipationAnswer.MAYBE]: 1,
  [EventParticipationAnswer.YES]: 2,
};

function scoreDateOption(dateOption: DateOption): number {
  return dateOption.answers.map((a) => ANSWER_WEIGHTS[a.answer]).reduce((a, b) => a + b, 0);
}

function calculatePercentage(dateOption: DateOption): number {
  return (
    (scoreDateOption(dateOption) /
      dateOption.answers.filter((a) => a.answer !== EventParticipationAnswer.PENDING).length /
      ANSWER_WEIGHTS[EventParticipationAnswer.YES]) *
    100
  );
}

const ANSWER_ORDER = [
  EventParticipationAnswer.YES,
  EventParticipationAnswer.MAYBE,
  EventParticipationAnswer.NO,
  EventParticipationAnswer.PENDING,
];

function sortParticipants(participants: EventParticipant[]) {
  const newParticipants = [...participants];
  return newParticipants.sort((a, b) => {
    const aIndex = ANSWER_ORDER.indexOf(a.answer ?? EventParticipationAnswer.PENDING);
    const bIndex = ANSWER_ORDER.indexOf(b.answer ?? EventParticipationAnswer.PENDING);

    if (aIndex === bIndex && a.answer && b.answer) {
      return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
    }

    if (aIndex === bIndex && !a.answer && !b.answer) {
      const at = a.answers?.[0]?.updatedAt;
      const bt = b.answers?.[0]?.updatedAt;
      if (!at || !bt) return 0;
      return new Date(at).getTime() - new Date(bt).getTime();
    }

    return aIndex - bIndex;
  });
}

function sortDateOptions(options: DateOption[], type: 'score' | 'time' = 'score'): DateOption[] {
  const newOptions = [...options];
  return newOptions.sort((a, b) =>
    type === 'score'
      ? scoreDateOption(b) - scoreDateOption(a)
      : new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
  );
}

function sortEvents(events: Event[]): Event[] {
  const newEvents = [...events];
  return newEvents.sort((a, b) => {
    const aDate = a.dateOptions?.length ? a.dateOptions[0].startTime : a.startTime;
    const bDate = b.dateOptions?.length ? b.dateOptions[0].startTime : b.startTime;
    return new Date(aDate).getTime() - new Date(bDate).getTime();
  });
}

function formatDate<T extends { startTime: Date; endTime?: Date }>(
  { startTime, endTime }: T,
  type: 'date' | 'time' = 'date',
): string {
  const { language } = (store.getState() as RootState).application;

  const startDate = new Date(startTime);
  const endDate = endTime ? new Date(endTime) : undefined;

  const locale = language === Language.EN ? enUS : nl;
  const formatter = type === 'date' ? 'EEEEEE. d LLLL' : 'HH:mm';
  const dateDiff = endDate ? dateUtils.calculateDayDifference(startDate, endDate) : undefined;

  return `${capitalizeWords(
    format(
      startTime,
      startDate.getMonth() === endDate?.getMonth() ? formatter.replace(' LLLL', '') : formatter,
      { locale },
    ),
  )}${endTime ? ` - ${capitalizeWords(format(endTime, formatter, { locale }))}` : ''} ${
    dateDiff && dateDiff > 0 && type === 'time' ? `(+${+dateDiff})` : ''
  }`;
}

/**
 * Transforms events to a list of attendance entries in the form of [name of user, data]
 *
 * @param events
 */
function transformToAttendanceEntriesPerUser(events: Event[]): [string, AttendantData][] {
  return _.chain(events)
    .map((event) => ({
      ...event,
      participants: event.participants.map((p) => ({ ...p, event })),
    }))
    .flatMap((x) => x.participants)
    .groupBy((x) => x.id)
    .mapKeys((v) => `${v[0].firstName} ${v[0].lastName}`)
    .mapValues((v, k) => ({
      id: v[0].id,
      name: k,
      yesFraction:
        v.length === 0
          ? 0
          : v.filter((x) => x.answer === EventParticipationAnswer.YES).length / v.length,
      ...Object.values(EventParticipationAnswer).reduce((acc, curr) => {
        acc[curr] = v.filter((x) => x.answer === curr).length;
        return acc;
      }, {} as any),
    }))
    .entries()
    .orderBy((v) => v[1][EventParticipationAnswer.YES], 'desc')
    .value();
}

/**
 * Transforms events to a list of attendance entries in the form of [name and date of event as a string, data]
 *
 * @param events
 */
function transformToAttendanceEntriesPerEvent(events: Event[]): [string, AttendantData][] {
  return _.chain(events)
    .map((ev) => [`${ev.title} (${formatSingleDate(ev.startTime)})`, ev] as const)
    .map(([k, v]) => ({
      id: v.id,
      name: k,
      yesFraction:
        v.participants.length === 0
          ? 0
          : v.participants.filter((x) => x.answer === EventParticipationAnswer.YES).length /
            v.participants.length,
      ...Object.values(EventParticipationAnswer).reduce((acc, curr) => {
        acc[curr] = v.participants.filter((x) => x.answer === curr).length;
        return acc;
      }, {} as any),
    }))
    .entries()
    .value();
}

/**
 * Transforms events to a list of attendance entries in the form of [name of user, data]
 *
 * @param events
 */
function transformToAttendanceToTop3(events: Event[]): ([string, AttendantData] | undefined)[] {
  return _.chain(events)
    .map((event) => ({
      ...event,
      participants: event.participants.map((p) => ({ ...p, event })),
    }))
    .flatMap((x) => x.participants)
    .groupBy((x) => x.id)
    .mapKeys((v) => `${v[0].firstName} ${v[0].lastName}`)
    .mapValues((v, k) => ({
      id: v[0].id,
      name: k,
      picture: v[0].picture,
      yesFraction:
        v.length === 0
          ? 0
          : v.filter((x) => x.answer === EventParticipationAnswer.YES).length / v.length,
      ...Object.values(EventParticipationAnswer).reduce((acc, curr) => {
        acc[curr] = v.length === 0 ? 0 : v.filter((x) => x.answer === curr).length;
        return acc;
      }, {} as any),
    }))
    .entries()
    .orderBy((v) => v[1][EventParticipationAnswer.YES], 'desc')
    .take(3)
    .value();
}

function eventsToProfilePictures(events: Event[]): Record<string, string> {
  return _.chain(events)
    .flatMap((x) => x.participants)
    .uniqBy((x) => x.id)
    .transform((acc, curr) => {
      acc[`${curr.firstName} ${curr.lastName}`] = curr.picture;
    }, {} as Record<string, string>)
    .value();
}

function getInitialNotificationSettings(isDatePicker: boolean): NotificationSettings {
  const participantEventTypes = [
    EventNotificationType.EVENT_INVITE,
    EventNotificationType.EVENT_UPDATE,
    EventNotificationType.EVENT_CANCELLED,
    EventNotificationType.EVENT_ANSWER_REMIND,
    EventNotificationType.EVENT_PARTICIPANT_ANSWERED,
    EventNotificationType.EVENT_PARTICIPANT_DECLINED,
  ];

  if (isDatePicker) {
    participantEventTypes.unshift(
      EventNotificationType.EVENT_DATEPICKER_INVITE,
      EventNotificationType.EVENT_DATEPICKER_RESPONDED,
      EventNotificationType.EVENT_DATEPICKER_PICKED,
    );
  } else {
    participantEventTypes.unshift(
      EventNotificationType.EVENT_INVITE,
      EventNotificationType.EVENT_UPDATE,
      EventNotificationType.EVENT_CANCELLED,
    );
  }

  const organizerEventTypes = [
    EventNotificationType.EVENT_PARTICIPANT_ANSWERED,
    EventNotificationType.EVENT_PARTICIPANT_DECLINED,
  ];
  const settings: NotificationSettings = {
    organizers: {},
    participants: {},
  };

  participantEventTypes.forEach((eventType) => {
    settings.participants![eventType] = {
      setting: true,
    };
  });

  organizerEventTypes.forEach((eventType) => {
    settings.organizers![eventType] = {
      setting: true,
    };
  });

  return settings;
}

function fromEventToCreateEventModel(event: Event): CreateEventModel {
  return {
    title: event.title ?? undefined,
    description: event.description ?? undefined,
    location: event.location ?? undefined,
    locationUrl: event.locationUrl ?? undefined,
    onlineLocation: event.onlineLocation ?? false,
    startTime: event.startTime ?? undefined,
    endTime: event.endTime ?? undefined,
    isRecurring: false,
    frequency: undefined,
    deadline: event.deadline,
    maximumAttendees: event.maximumAttendees ?? undefined,
    type: event.type ?? undefined,
    picture: event.picture ?? undefined,
    isDatePicker: event.isDatePicker ?? undefined,
    notificationSettings: _.isEqual(event.notificationSettings, {})
      ? undefined
      : event.notificationSettings ?? undefined,
    customFields:
      (event.customFields.map((f) =>
        _.omit(f, 'responses', 'customfieldId'),
      ) as EventCustomField[]) ?? undefined,
    dateOptions: event.dateOptions.map((o) => _.omit(o, 'id', 'answers')) ?? undefined,
  };
}
export default {
  ANSWER_WEIGHTS,
  scoreDateOption,
  calculatePercentage,
  sortDateOptions,
  formatDate,
  sortEvents,
  transformToAttendanceEntriesPerUser,
  transformToAttendanceEntriesPerEvent,
  transformToAttendanceToTop3,
  eventsToProfilePictures,
  getInitialNotificationSettings,
  fromEventToCreateEventModel,
  sortParticipants,
};
