import { toast } from 'react-toastify';
import { isValidPhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';
import validator from 'email-validator';
import i18n from '../locales/i18n';
import { ProfileDataType, ProfileField } from '../types/Profile';
import { LocalContactInfo } from '../services/model/localContactService.model';
import {
  BusinessInvite,
  CustomField,
  CustomFieldResponses,
  CustomFieldType,
  CustomMultipleChoiceField,
} from '../services/model/inviteService.model';
import { filterCustomLabelFields } from './filterUtils';

export function isValidEmail(email: string): boolean {
  /* eslint-disable no-useless-escape */
  return validator.validate(email);
}

export function isEmptyEmail(field: ProfileField): boolean {
  return !field.email;
}

export function isEmptyPhoneNumber(field: ProfileField): boolean {
  return !field.phoneNumber?.prefix && !field.phoneNumber?.suffix;
}

export function isEmptyAddress(field: ProfileField): boolean {
  const { address } = field;
  if (!address) return true;
  return (
    !address.street &&
    !address.houseNumber &&
    !address.houseNumberAddition &&
    !address.city &&
    !address.postCode &&
    !address.country &&
    !address.countryCode
  );
}

export function isEmptyBirthDate(field: ProfileField): boolean {
  return !field.birthDate || field.birthDate === '--';
}

export function isEmptyField(field: ProfileField): boolean {
  switch (field.dataType) {
    case 'EMAIL':
      return isEmptyEmail(field);
    case 'PHONENUMBER':
      return isEmptyPhoneNumber(field);
    case 'ADDRESS':
      return isEmptyAddress(field);
    case 'BIRTHDATE':
      return isEmptyBirthDate(field);
    default:
      return false;
  }
}

export function isValidProfileFields(fields: ProfileField[]): boolean {
  const invalid = fields
    .filter((field): boolean => !IsValidField(field))
    .map((field): string => field.dataType);
  if (invalid.length === 0) return true;
  if (invalid.includes(ProfileDataType.EMAIL)) {
    toast.error(i18n.t('toast.error.field.email'));
    return false;
  }
  if (invalid.includes(ProfileDataType.PHONENUMBER)) {
    toast.error(i18n.t('toast.error.field.phoneNumber'));
    return false;
  }
  if (invalid.includes(ProfileDataType.ADDRESS)) {
    toast.error(i18n.t('toast.error.field.address'));
    return false;
  }
  if (invalid.includes(ProfileDataType.BIRTHDATE)) {
    toast.error(i18n.t('toast.error.field.birthDate'));
    return false;
  }
  return true;
}

export function IsValidEmailField(field: ProfileField, canBeEmpty: boolean): boolean {
  return (canBeEmpty && isEmptyEmail(field)) || isValidEmail(field.email!);
}

export function IsValidPhoneNumberField(field: ProfileField, canBeEmpty: boolean): boolean {
  return (
    (canBeEmpty && isEmptyPhoneNumber(field)) ||
    isValidPhoneNumber(`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`)
  );
}

export function IsValidAddressField(field: ProfileField, canBeEmpty: boolean): boolean {
  if (canBeEmpty && isEmptyAddress(field)) return true;
  const { address } = field;
  if (!address) return false;

  return (
    !!address.street &&
    !!address.houseNumber &&
    !!address.city &&
    !!address.postCode &&
    !!address.country &&
    !!address.countryCode
  );
}

export function IsValidBirthDateField(field: ProfileField, canBeEmpty: boolean): boolean {
  const [year, month, day] = field.birthDate?.split('-') || [];
  if (!year && !month && !day) return canBeEmpty;
  if (Number.isNaN(+year) || Number.isNaN(+month) || Number.isNaN(+day)) return false;
  if (!year || !month || !day) return false;
  if (+month < 1 || +month > 12) return false;
  if (+day < 1 || +day > 31) return false;
  const currentDate = new Date();
  const selectedDate = new Date(+year, +month - 1, +day);
  currentDate.setHours(0, 0, 0, 0);

  if (selectedDate > currentDate) return false;
  return true;
}

export function IsValidField(field: ProfileField, canBeEmpty: boolean = true): boolean {
  switch (field.dataType) {
    case ProfileDataType.EMAIL:
      return IsValidEmailField(field, canBeEmpty);
    case ProfileDataType.PHONENUMBER:
      return IsValidPhoneNumberField(field, canBeEmpty);
    case ProfileDataType.ADDRESS:
      return IsValidAddressField(field, canBeEmpty);
    case ProfileDataType.BIRTHDATE:
      return IsValidBirthDateField(field, canBeEmpty);
    case ProfileDataType.SOCIAL:
    case ProfileDataType.BUSINESSNAME:
      return true;
    default:
      return false;
  }
}

export function isValidLocalContact(info: LocalContactInfo, invite: BusinessInvite): boolean {
  if (!info.firstName || !info.lastName) return false;
  if (info.receivedData.filter((f) => !isEmptyField(f)).some((f) => !IsValidField(f))) return false;
  if (invalidCustomFieldResponses(invite.customFields, info.customFieldResponses).length)
    return false;

  return true;
}

export function invalidCustomFieldResponses(
  fields: CustomField[],
  response: CustomFieldResponses,
): CustomField[] {
  const customFields = filterCustomLabelFields(fields);
  const invalidMC = (
    customFields.filter(
      (f) => f.type === CustomFieldType.MULTIPLE_CHOICE,
    ) as CustomMultipleChoiceField[]
  ).filter(
    (f: CustomMultipleChoiceField): boolean =>
      !!response[f.customFieldId!] && !f.options.includes(response[f.customFieldId!]),
  );
  return invalidMC;
}

export function isEqualBusinessInvite(invite1: BusinessInvite, invite2: BusinessInvite): boolean {
  return (
    _.isEqual(new Set(invite1.mandatoryFields), new Set(invite2.mandatoryFields)) &&
    _.isEqual(new Set(invite1.customFields), new Set(invite2.customFields)) &&
    _.isEqual(new Set(invite1.optionalFields), new Set(invite2.optionalFields)) &&
    _.isEqual(new Set(invite1.nonRequestedFields), new Set(invite2.nonRequestedFields))
  );
}

/**
 * Checks if the given string is a valid URL
 * @param string
 */
export function isValidHttpUrl(string: string) {
  const attempts = [string, `https://${string}`, `http://${string}`];

  if (!string.includes('.')) return false; // No domain (e.g. 'google' instead of 'google.com')

  for (let i = 0; i < attempts.length; i += 1) {
    const attempt = attempts[i];
    try {
      const possibleUrl = new URL(attempt);
      return possibleUrl.protocol === 'http:' || possibleUrl.protocol === 'https:';
    } catch (e) {
      // Do nothing
    }
  }

  return false;
}

/**
 * Formats the given url to a valid url by adding 'https://' if it's missing
 * @param url
 */
export function formatUrl(url: string): string {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  return `https://${url}`;
}
