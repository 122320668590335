import React, { Dispatch, SetStateAction, useMemo } from 'react';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_50,
} from '../../../constants';
import { SelectOption } from '../../../types/misc';

interface CountryDropdownProps {
  netherlandsFirst?: boolean;
  selectedCountry: SelectOption;
  setSelectedCountry: Dispatch<SetStateAction<SelectOption>>;
}

export default function CountryDropdown({
  netherlandsFirst = false,
  selectedCountry,
  setSelectedCountry,
}: CountryDropdownProps): JSX.Element {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return netherlandsFirst
      ? [
          { value: 'NL', label: 'Netherlands' },
          ...countryList()
            .getData()
            .filter((c) => c.value !== 'NL'),
        ]
      : countryList().getData();
  }, [netherlandsFirst]);
  const [error] = React.useState<boolean>(false);

  return (
    <Select
      styles={{
        control: (baseStyles: any, state) => ({
          ...baseStyles,
          borderColor: !error ? (state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY) : '#EF4444',
          borderRadius: '9999px',
          color: COLOR_PRIMARY_900,
          backgroundColor: COLOR_SECONDARY_50,
          boxShadow: 'none',
          height: '40px',
          '&:hover': {
            borderColor: !error ? (state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY) : '#EF4444',
          },
        }),
        option: (styles: any, { isSelected, data }) => {
          return {
            ...styles,
            backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
            color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
            '&:hover': {
              backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
              color: COLOR_PRIMARY_900,
            },
            borderBottom:
              netherlandsFirst && data.value === 'nl' ? `2px solid ${COLOR_PRIMARY_900}` : '',
          };
        },
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (baseStyles: any, state) => ({
          ...baseStyles,
          color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
        }),
      }}
      options={options as any}
      placeholder={t('form.address.countryPlaceholder')}
      value={selectedCountry}
      onChange={(v) => setSelectedCountry(v)}
      className="mb-1"
    />
  );
}
