import React from 'react';
import { HiOutlineKey } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import {
  FaAt,
  FaBell,
  FaCalendar,
  FaCalendarCheck,
  FaEnvelope,
  FaEuroSign,
  FaStar,
  FaTicket,
} from 'react-icons/fa6';
import { FaFileAlt, FaThumbtack } from 'react-icons/fa';
import { HiOutlineChatBubbleLeftEllipsis, HiOutlineHome, HiUserGroup } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';
import DropdownItem from './DropdownItem';
import Navigation from './Navigation';
import { isCommunity } from '../../../../../constants';
import { RootState } from '../../../../../redux/reducers';
import { OnboardingStep, SubscriptionType } from '../../../../../types/misc';
import useActiveSubscription from '../../../../../hooks/business/useActiveSubscription';

export default function DropdownPanel(): JSX.Element {
  const { t } = useBusinessTranslation();
  const {
    connections,
    application: { onboardingStep },
  } = useSelector((state: RootState) => state);
  const connectionsWithPhoneNumber = connections.filter((c) => c.fields.PHONENUMBER);
  const connectionsWithEmail = connections.filter((c) => c.fields.EMAIL);
  const connectionsWithAddress = connections.filter((c) => c.fields.ADDRESS);
  const activeSubscription = useActiveSubscription();
  const hasSubscription = activeSubscription !== SubscriptionType.FREE;

  const communication = [
    {
      icon: <FaBell className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.sendPushNotification'),
      path: '/communication/push-notification',
      state: { connections: connectionsWithPhoneNumber },
    },
    {
      icon: <FaAt className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.sendEmail'),
      path: '/communication/email',
      state: { connections: connectionsWithEmail },
    },
    {
      icon: <FaTicket className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.orderAddressLabels'),
      path: '/payment/address-label',
      state: { connections: connectionsWithAddress },
    },
    {
      icon: <FaEnvelope className="w-5 h-5 flex-shrink-0" />,
      name: t('page.dashboard.quickAction.sendCard'),
      path: '/payment/postcard',
      state: { connections: connectionsWithAddress },
    },
  ];

  const agenda = [
    {
      icon: <FaCalendar className="w-5 h-5 flex-shrink-0" />,
      name: t('page.event.regular.title'),
      path: '/events',
    },
    {
      icon: <FaThumbtack className="w-5 h-5 flex-shrink-0/>" />,
      name: t('page.event.date_picker.title'),
      path: '/events/date-picker',
    },
    {
      icon: <FaFileAlt className="w-5 h-5 flex-shrink-0" />,
      name: t('page.agenda.report.title'),
      path: '/events/report',
    },
  ];

  const general = [
    {
      icon: <FaEuroSign className="w-5 h-5 flex-shrink-0" />,
      name: t('page.settings.subscriptions.title'),
      condition: isCommunity && !hasSubscription,
      path: '/settings/subscriptions',
    },
    {
      icon: <FaStar className="w-5 h-5 flex-shrink-0" />,
      name: t('page.settings.subscriptions.manageSubscriptionLink'),
      condition: isCommunity && hasSubscription,
      path: '/subscriptions/manage',
    },
    {
      icon: <HiOutlineKey className="w-5 h-5 flex-shrink-0" />,
      name: t('page.settings.admins.title'),
      path: '/settings/admins',
    },
    {
      icon: <FiSettings className="w-5  h-5 flex-shrink-0" />,
      name: t('page.settings.general.title'),
      id: 'settings',
      path: '/settings',
    },
  ];

  return (
    <>
      <Navigation
        id="overview"
        icon={<HiOutlineHome className="h-5 w-5" />}
        name={t('general.overview')}
        path="/overview"
      />
      <Navigation
        id="contacts"
        icon={<HiUserGroup className="h-5 w-5" />}
        name={t('page.dashboard.clientList.title')}
        path="/contacts"
      />
      <DropdownItem
        id="communication"
        duration={onboardingStep !== undefined ? 100 : undefined}
        open={onboardingStep === OnboardingStep.COMMUNICATION || undefined}
        name={t('general.communication')}
        icon={HiOutlineChatBubbleLeftEllipsis}>
        {communication.map((r) => (
          <Navigation {...r} key={r.path} open />
        ))}
      </DropdownItem>
      {isCommunity && (
        <DropdownItem
          duration={onboardingStep !== undefined ? 100 : undefined}
          id="events"
          open={onboardingStep === OnboardingStep.EVENTS || undefined}
          name={t('general.agenda')}
          icon={FaCalendarCheck}>
          {agenda.map((r) => (
            <Navigation {...r} key={r.path} open />
          ))}
        </DropdownItem>
      )}
      {general
        .filter((r) => r.condition === undefined || r.condition)
        .map((r) => (
          <Navigation {...r} key={r.path} />
        ))}
    </>
  );
}
