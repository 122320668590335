import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isMoments } from '../constants';
import { capitalizeFirstLetter } from '../utils/stringUtils';
import useLanguage from './account/useLanguage';

export default function useBusinessTranslation() {
  const { t: translate } = useTranslation();
  const lang = useLanguage();

  const defaultKeys = useMemo(
    () => ({
      business_one: isMoments ? translate('business.moment') : translate('business.community'),
      business_many: isMoments ? translate('business.moments') : translate('business.communities'),
      Business_one: isMoments
        ? capitalizeFirstLetter(translate('business.moment'))
        : capitalizeFirstLetter(translate('business.community')),
      Business_many: isMoments
        ? capitalizeFirstLetter(translate('business.moments'))
        : capitalizeFirstLetter(translate('business.communities')),
      business_name: isMoments ? 'Vera Moments' : 'Vera Community',
    }),
    [lang],
  );

  const t = (...args: Parameters<typeof translate>) => {
    if (args.length === 1) return translate(args[0], defaultKeys);
    if (args.length === 2)
      return translate(args[0], { ...defaultKeys, ...(args[1] as Record<string, string>) });
    if (args.length === 3)
      return translate(args[0] as any, args[1] as string, {
        ...defaultKeys,
        ...(args[2] as Record<string, string>),
      });
    return '';
  };

  return { t };
}
