import { FaShare } from 'react-icons/fa';
import React from 'react';
import Button from './Button';

interface ShareButtonProps {
  title: string;
  text: string;
  url: string;
  className?: string;
}

/*
 * A button component with a share icon.
 *
 * @param {() => void} onClick - The function to execute on click.
 * @param {string} text - The text to display on the button.
 * @param {string} className - The class name for the button.
 * @returns {JSX.Element} - The button component.
 */
export default function ShareButton({
  title,
  text,
  url,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  className,
}: ShareButtonProps): JSX.Element {
  const handleShare = () => {
    const shareData = {
      title,
      text,
      url,
    };

    navigator
      .share(shareData)
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
  };

  return (
    <Button variant="primary" onClick={handleShare}>
      <FaShare className="h-5 w-5 mr-2 inline -mt-1" />
      Deel
    </Button>
  );
}
