import React, { forwardRef, ComponentProps } from 'react';
import {
  MobileDatePicker,
  MobileDateTimePicker,
  renderTimeViewClock,
  StaticDatePicker,
  StaticDateTimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';

type DatePickerProps = {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  static?: boolean;
} & (
  | ({ includeTime: true } & ComponentProps<typeof MobileDateTimePicker>)
  | ({ includeTime?: false } & ComponentProps<typeof MobileDatePicker>)
);

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      date: dateProp,
      setDate,
      includeTime = true,
      static: staticForm = false,
      slotProps: { textField, ...slotProps } = {},
      ...props
    },
    ref,
  ) => {

    const baseProps = {
      onChange: (date: dayjs.Dayjs) => setDate(date?.toDate()),
      value: dateProp ? dayjs(dateProp) : undefined,
      slotProps: {
        textField: {
          inputRef: ref,
          ...textField,
        },
        ...slotProps,
      },
      ...props,
    };

    const dateTimeProps = {
      ampm: false,
      format: 'DD-MM-YYYY HH:mm',
      viewRenderers: {
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      },
      ...baseProps,
    };

    if (includeTime) {
      if (staticForm) {
        return (
          <StaticDateTimePicker
            {...(dateTimeProps as ComponentProps<typeof StaticDateTimePicker>)}
          />
        );
      }
      return (
        <MobileDateTimePicker {...(dateTimeProps as ComponentProps<typeof MobileDateTimePicker>)} />
      );
    }

    if (staticForm)
      return <StaticDatePicker {...(baseProps as ComponentProps<typeof StaticDatePicker>)} />;

    return (
      <MobileDatePicker
        format="DD-MM-YYYY"
        {...(baseProps as ComponentProps<typeof MobileDatePicker>)}
      />
    );
  },
);

export default DatePicker;
