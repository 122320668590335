import React from 'react';

export default function PrimaryOverlay({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div
      className="bg-primary-900 rounded-lg bg-opacity-50 absolute left-0 top-0 w-full h-full z-20 backdrop-filter backdrop-blur-md flex justify-center items-center">
      {children}
    </div>
  );
}
