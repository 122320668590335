import React, { useEffect, useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import ImageInput from './ImageInput';
import businessService from '../../../services/businessService';
import ApiImage from '../misc/ApiImage';
import { UploadedPictureModel } from '../../../services/model/businessService.model';

interface BusinessImageInputProps {
  image: string | undefined;
  setImage: (image: string | undefined) => void;
  className?: string;
}

export default function BusinessImageInput({
  image,
  setImage,
  className,
}: BusinessImageInputProps): JSX.Element {
  const [images, setImages] = useState<UploadedPictureModel[]>([]);

  useEffect(() => {
    businessService.getPicturesForBusiness().then(setImages);
  }, []);

  const handlePictureOpload = async (picture: string | undefined) => {
    if (!picture) {
      setImage(undefined);
    } else {
      const file = await fetch(picture).then((res) => res.blob() as Promise<File>);
      businessService.uploadPicture(file).then((path) => {
        setImage(path.picture);
        setImages([...images, path]);
      });
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {images.length < 5 ? (
        <ImageInput
          iconClassName="size-12"
          className={`object-cover ${className}`}
          image={image}
          setImage={handlePictureOpload}
        />
      ) : (
        <ApiImage path={image ?? images[0].picture} className={`rounded-lg ${className}`} />
      )}
      <div className="flex gap-4">
        {images.map((p) => (
          <div key={p.id} className="relative">
            <div onClick={() => setImage(p.picture)}>
              <ApiImage
                key={p.id}
                path={p.picture}
                className="w-[50px] object-cover cursor-pointer h-[50px] rounded-[8px]"
              />
            </div>
            <button
              type="button"
              onClick={() =>
                businessService
                  .deletePicture(p.id)
                  .then(() => setImages(images.filter((i) => i.id !== p.id)))
              }
              className="absolute -top-2 -right-2 p-1 bg-secondary-200 rounded-full">
              <HiTrash className="w-4 h-4 text-primary-900" />
            </button>
          </div>
        ))}
      </div>
      {images.length === 5 && <p className="-mt-2">Je kunt maximaal 5 foto uploaden</p>}
    </div>
  );
}
