import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa6';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { IconType } from 'react-icons';
import { toast } from 'react-toastify';
import { FiCheckCircle } from 'react-icons/fi';
import { SubscriptionType } from '../../../types/misc';
import {
  getUsageLimit,
  memberCountPrices,
  subscriptionFeatures,
} from '../../../utils/subscriptionUtils';
import CheckoutForm from '../../components/forms/CheckoutForm';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import usePersonalConnections from '../../../hooks/account/usePersonalConnections';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import Button from '../../components/buttons/Button';
import { stripeAppearance } from '../../../constants';
import { subscriptionService } from '../../../services/subscriptionService';

const STRIPE_KEY: string = process.env.REACT_APP_STRIPE_KEY as string;

const stripe = loadStripe(STRIPE_KEY);

export default function UpgradeSubscriptionPage(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: '' });
  const connections = usePersonalConnections();
  const location = useLocation();
  const subscriptionType = location.state?.subscriptionType as SubscriptionType | undefined;
  const business = useSelectedBusiness();
  const activeSubscription = useActiveSubscription();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [customerId, setCustomerId] = useState<string | undefined>(undefined);
  const [disabledForm, setDisabledForm] = useState<boolean>(false);
  const [agreedWithTerms, setAgreedWithTerms] = useState<boolean>(false);
  const [planChangeMode, setPlanChangeMode] = useState<boolean>();

  useEffect(() => {
    if (business && business.businessId && planChangeMode === undefined) {
      subscriptionService
        .prepareSubscription()
        .then(({ status, clientSecret: cs, customerId: cid }) => {
          if (status === 'subscription_exists') {
            setPlanChangeMode(true);
          } else {
            setClientSecret(cs);
            setCustomerId(cid);
            setPlanChangeMode(false);
          }
        });
    }
  }, [business]);

  const changeSubscription = async () => {
    if (!clientSecret && subscriptionType && business && business.businessId !== undefined) {
      const product = getUsageLimit(subscriptionType, connections.length);

      if (product && product.priceId) {
        subscriptionService
          .upgradeSubscription(product.priceId)
          .then(() => navigate('/subscriptions/manage'))
          .catch(() => toast.error(t('page.subscriptions.upgrade.error')));
      } else {
        toast.error(t('page.subscriptions.upgrade.error'));
      }
    }
  };

  const options = {
    appearance: { ...stripeAppearance },
    clientSecret,
  } as StripeElementsOptions;

  if (!subscriptionType || planChangeMode === undefined) {
    return <></>;
  }

  return (
    <div className="flex items-start justify-between gap-8 min-h-full">
      <div className="flex-1 rounded-3xl bg-secondary-200 p-8">
        {planChangeMode && <p className="text-lg">{t('page.subscriptions.upgrade.changePlan')}</p>}
        <h2 className="text-4xl flex items-center gap-4 font-serif">
          <div>
            <p>{activeSubscription}</p>
          </div>
          <FaArrowRight />
          <div>
            <p>{subscriptionType}</p>
          </div>
        </h2>
        <p className="flex gap-2 py-4">{t('page.subscriptions.upgrade.explanation')}</p>
        <ul className="flex flex-col gap-2">
          {memberCountPrices[subscriptionType].map((price) => (
            <li className="flex items-center gap-4">
              <div className="w-3 h-3 bg-secondary rounded-full" />
              {Number.isFinite(price.max)
                ? `${price.min} - ${price.max}: `
                : `${t('page.subscriptions.upgrade.over')} ${price.min - 1}: `}

              {price.fee === -1
                ? t('page.subscriptions.upgrade.talkToSales')
                : `€${price.fee.toFixed(2)} ${t('page.settings.subscriptions.perMonth')}`}
            </li>
          ))}
        </ul>
        <div className="flex flex-col">
          <h3 className="text-lg font-bold my-2">{t('page.subscriptions.upgrade.benefits')}</h3>
          {subscriptionFeatures
            .filter((f) => f[subscriptionType])
            .map((feature) => (
              <div key={feature.i18nKey} className="flex items-center gap-2">
                <FiCheckCircle />
                {t(`page.settings.subscriptions.${feature.i18nKey}`)}
              </div>
            ))}
        </div>
      </div>
      <div data-testid="sub-payment" className="flex-1 mt-6">
        <h2 className="text-2xl font-semibold">{t('page.subscriptions.upgrade.title')}</h2>
        <ul className="flex flex-col gap-2 mt-2">
          <ConditionListItem
            icon={FiCheckCircle}
            text={t('page.subscriptions.upgrade.freeTrial')}
          />
          <ConditionListItem
            icon={FiCheckCircle}
            text={t('page.subscriptions.upgrade.subscriptionDetails')}
          />
          <ConditionListItem
            icon={FiCheckCircle}
            text={t('page.subscriptions.upgrade.cancelPolicy')}
          />
        </ul>
        {clientSecret && !planChangeMode ? (
          <Elements options={options} stripe={stripe}>
            <CheckoutForm
              clientSecret={clientSecret}
              disabled={disabledForm}
              setDisabled={setDisabledForm}
              intentType="setup"
              onSubmit={() => {}}
              redirectQueryParams={`?priceId=${
                getUsageLimit(subscriptionType, connections.length).priceId
              }&customerId=${customerId}`}
            />
          </Elements>
        ) : (
          <div className="flex flex-col">
            <div className="hover:cursor-pointer my-8">
              <input
                id="terms"
                type="checkbox"
                checked={agreedWithTerms}
                onChange={() => setAgreedWithTerms((prevState) => !prevState)}
              />
              <label
                htmlFor="terms"
                className="ml-2 font-medium text-b-900 dark:text-black-300 hover:cursor-pointer">
                {t('page.subscriptions.upgrade.accept')}
              </label>
            </div>
            <Button
              disabled={!agreedWithTerms}
              className="self-end"
              onClick={changeSubscription}
              variant="primary">
              {t('page.subscriptions.upgrade.confirmChange')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function ConditionListItem({ icon: Icon, text }: { icon: IconType; text: string }): JSX.Element {
  return (
    <li className="flex items-center gap-1.5">
      <Icon className="self-start shrink-0 text-xl mt-0.5" />
      {text}
    </li>
  );
}
