import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../../../types/Profile';

interface BusinessNameInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
}

export default function BusinessNameInput({
  field,
  setField,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canBeEmpty = false,
  mandatory = false,
}: BusinessNameInputProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-row items-center rounded-lg">
      <div className="flex w-full flex-col">
        <label htmlFor={field.id} className="focus:border-3 mb-1 pr-2 font-medium">
          {t('form.businessName.name')} {mandatory ? '*' : ''}
        </label>
        <input
          id={field.id}
          placeholder={t('form.businessName.name')}
          className="focus:border-3 h-10 w-full rounded-lg px-2 text-gray-600 focus:ring-0 focus:ring-transparent"
          name="businessName"
          type="text"
          value={field.description ?? ''}
          onChange={(e) => {
            setField({ ...field, description: e.target.value });
          }}
        />
      </div>
    </div>
  );
}
