import React, { useEffect } from 'react';
import classNames from 'classnames';
import { AttendantData, Event, EventParticipationAnswer } from '../../../types/event';
import eventUtils from '../../../utils/eventUtils';
import { COLOR_PRIMARY, COLOR_PRIMARY_300, COLOR_PRIMARY_900 } from '../../../constants';
import ProfilePicture from '../misc/ProfilePicture';
import AttendanceTooltip from './components/AttendanceTooltip';

type PodiumGraphProps = {
  events: Event[];
};

type RankingPlace = 'first' | 'second' | 'third';

type PlaceData = {
  id: number;
  place: RankingPlace;
  color: string;
  heightClassname: string;
  podiumOffsetClassname: string;
  name: string;
  userData: AttendantData;
}

type Ranking = PlaceData[];

export default function PodiumGraph({ events }: PodiumGraphProps): JSX.Element {
  const [top3, setTop3] = React.useState<Ranking>([]);

  useEffect(() => {
    const [first, second, third] = eventUtils.transformToAttendanceToTop3(events);
    setTop3(() => [
      toPlaceData('second', second),
      toPlaceData('first', first),
      toPlaceData('third', third),
    ]);
  }, [events]);

  return (
    <div className="flex flex-col w-full min-h-[20rem]">
      <div className="flex gap-4 items-end justify-center">
        {top3.map((entry, index) => (
          <div className="relative group w-full max-w-20">
            <div key={`${entry.id}_pfp_podium_${index}`} className="flex flex-col items-center">
              <ProfilePicture className="w-10 h-10 mb-3" picture={entry.userData.picture}
                              alias={entry.name.split(' ').join('+')} />
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames('rounded-t-xl max-w-14 w-full', entry.heightClassname)}
              />
              {entry.place !== 'third' &&
                <div
                  className={classNames('w-full bg-secondary-200 border-t-2 border-l-2 border-r-2 border-secondary-50 rounded-t-xl', entry.podiumOffsetClassname)} />}
            </div>
            <AttendanceTooltip
              className="z-20 top-[calc(100%+0.5rem)] group-hover:opacity-100 group-hover:visible left-1/2 -translate-x-1/2 opacity-0 invisible transition absolute w-52"
              key={`tooltip_${entry.id}`} active
              payload={[{
                payload: {
                  ...entry.userData,
                  place: entry.place,
                  bgColor: entry.color,
                },
              }]} />
          </div>
        ))}
      </div>

      <div
        className="w-3/4 max-lg:w-full max-md:w-3/4 max-sm:w-full mx-auto h-10 bg-secondary-200 border-2 border-secondary-50 rounded-t-xl shadow-secondary-200 shadow-lg" />
    </div>
  );
}

const PLACE_TO_COLOR_MAP = {
  first: COLOR_PRIMARY_300,
  second: COLOR_PRIMARY,
  third: COLOR_PRIMARY_900,
};

const PLACE_TO_HEIGHT_MAP = {
  first: 'h-64',
  second: 'h-48',
  third: 'h-32',
};

const PLACE_TO_PODIUM_OFFSSET = {
  first: 'h-8',
  second: 'h-6',
  third: 'h-0',
};

function toPlaceData(place: RankingPlace, payload: [string, AttendantData] | undefined): PlaceData {
  if (!payload) {
    return {
      id: -1,
      place,
      name: '',
      color: PLACE_TO_COLOR_MAP[place],
      heightClassname: PLACE_TO_HEIGHT_MAP[place],
      podiumOffsetClassname: 'h-0',
      userData: {
        id: -1,
        name: '',
        yesFraction: 0,
        [EventParticipationAnswer.YES]: 0,
        [EventParticipationAnswer.NO]: 0,
        [EventParticipationAnswer.MAYBE]: 0,
        [EventParticipationAnswer.PENDING]: 0,
      },
    };
  }

  return {
    id: payload[1].id,
    podiumOffsetClassname: PLACE_TO_PODIUM_OFFSSET[place],
    place,
    name: payload[0],
    color: PLACE_TO_COLOR_MAP[place],
    heightClassname: PLACE_TO_HEIGHT_MAP[place],
    userData: payload[1],
  };
}
