import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  COLOR_DANGER,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_200,
  COLOR_SECONDARY_50,
} from '../../../constants';
import { Event, EventParticipationAnswer } from '../../../types/event';
import { decimalToPercentageString } from '../../../utils/stringUtils';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';
import Card from '../cards/Card';

type AnswerPercentageGraphProps = {
  events: Event[];
};

const COLOR_MAP = {
  [EventParticipationAnswer.YES]: COLOR_PRIMARY_300,
  [EventParticipationAnswer.NO]: COLOR_DANGER,
  [EventParticipationAnswer.MAYBE]: COLOR_PRIMARY_900,
  [EventParticipationAnswer.PENDING]: COLOR_SECONDARY_200,
};

export default function AnswerPercentageGraph({ events }: AnswerPercentageGraphProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.eventDetails.answerTitle' });
  const { t: tReport } = useTranslation('', { keyPrefix: 'page.agenda.report' });
  const isMd = useIsScreenSize('md');

  const answers = events
    .flatMap((event) => event.participants.map((p) => p.answer || EventParticipationAnswer.PENDING));

  const answerData = _.chain(answers)
    .countBy(x => x)
    .map((v, k) => ({
      value: (v / answers.length),
      color: COLOR_MAP[k as EventParticipationAnswer],
      label: t(k.toLowerCase()),
    }))
    .value();

  const biggest = _.maxBy(answerData, 'value') || { value: 0, label: '-', color: COLOR_DANGER };

  return (
    <Card className="bg-secondary-200 items-start bg-opacity-50 flex-[3]">
      <h3 className="font-serif text-xl">{tReport('answersTitle')}</h3>
      <div className="flex items-center max-sm:flex-wrap max-sm:justify-center">
        <div className="h-80 w-80 max-md:w-72 max-md:h-72">
          <ResponsiveContainer>
            <PieChart margin={{ top: 20, bottom: 10 }}>
              <Pie
                data={answerData}
                dataKey="value"
                nameKey="label"
                innerRadius="65%"
                outerRadius="100%"
              >
                {answerData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <circle cx="50%" cy="51%" fill={biggest.color} r={isMd ? 50 : 70} />
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={isMd ? 24 : 28}
                fontWeight="bold"
                fill={biggest.color === COLOR_SECONDARY_200 ? COLOR_PRIMARY_900 : COLOR_SECONDARY_50}
              >
                {(biggest.value * 100).toFixed(0)}%
              </text>
              <text
                x="50%"
                y="50%"
                dy={30}
                textAnchor="middle"
                fontSize={isMd ? 12 : 16}
                fill={biggest.color === COLOR_SECONDARY_200 ? COLOR_PRIMARY_900 : COLOR_SECONDARY_50}
              >
                {biggest.label}
              </text>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <CustomLegend payload={answerData} />
      </div>
    </Card>
  );
}

type RenderCustomLegendProps = {
  payload: { label: string; value: number, color: string }[];
};

function CustomLegend({ payload }: RenderCustomLegendProps): JSX.Element {
  return (
    <ul className="flex items-center gap-4 flex-wrap max-w-60">
      {payload.map((entry, index) => (
        <li className="px-1" key={`item-${index}`}>
          <div className="flex items-center gap-2">
            <div className="w-4 h-4 border border-secondary-50 rounded-full" style={{ backgroundColor: entry.color }} />
            <div>
              <p className="text-sm text-gray-500">{entry.label}</p>
              <p className="">{decimalToPercentageString(entry.value)}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
