import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ProfileField } from '../../../../types/Profile';
import { isValidEmail } from '../../../../utils/stringUtils';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';

interface EmailInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  validate?: boolean;
}

export default function EmailInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  validate = false,
}: EmailInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!field.email) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    setError(isValidEmail(field.email) ? '' : t('invalid.invalid'));
  }, [field.email, validate]);

  return (
    <div className="relative flex w-full flex-col">
      <label htmlFor={field.id} className="mb-1 pr-2 font-medium">
        {t('dataType.email')} {mandatory ? '*' : ''}
      </label>
      <input
        id={field.id}
        placeholder={t('dataType.email')}
        className={classNames(
          'h-10 w-full rounded-lg border px-2 text-gray-600 focus:ring-0 focus:ring-transparent',
          {
            '!border-error': error,
          },
        )}
        name="email"
        data-testid={`email-input-${field.id}`}
        type="text"
        value={field.email ?? ''}
        onChange={(e) => setField({ ...field, email: e.target.value })}
      />
      <p className="absolute right-1 top-0 text-sm text-error transition-all">{error}</p>
    </div>
  );
}
