import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import PrivacyAndConditionsModal from '../modals/PrivacyAndConditionsModal';
import Logo from '../icons/Logo';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <div data-testid="footer" className="bg-primary-900 rounded-t-[20px]">
        <div className="flex flex-col  ">
          <div data-testid="Container1" className="border-b border-secondary-50 w-full py-5">
            <div className="flex flex-col gap-10 md:gap-4 md:flex-row justify-between mx-auto max-w-7xl px-5 xl:px-0 w-full">
              <Logo dark />
            </div>
          </div>
          <div className="bg-primary-900 w-full h-fit min-h-[64px] py-4  flex text-medium  items-center px-4 desktop:px-0 md:py-0">
            <div className="flex md:flex-row flex-col justify-between  max-w-7xl md:items-center md:mx-auto w-full">
              <p className="text-secondary-50">{`Copyright ® ${new Date().getFullYear()} Vera Connect`}</p>
              <div className="flex w-fit mt-4 md:mt-0 md:gap-6 md:items-center md:flex-row flex-col">
                <Link className="text-secondary-50" to="/about">
                  {t('footer.about')}
                </Link>
                <p
                  onClick={(): void => setModalOpen(true)}
                  className="cursor-pointer text-secondary-50">
                  {t('footer.termsAndPrivacy')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrivacyAndConditionsModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}
