import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FaCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/misc/PageHeader';
import { SubscriptionType } from '../../../types/misc';
import { COLOR_PRIMARY_900 } from '../../../constants';
import { baseSubscriptionPrices, subscriptionFeatures } from '../../../utils/subscriptionUtils';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import businessService from '../../../services/businessService';

export function SubscriptionsPage() {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.subscriptions' });
  const activeSubscription = useActiveSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    businessService.fetchBusinesses();
  }, []);

  // Make grid into grid-cols-5 on the div under PageHeader to use a Base subscription also
  return (
    <>
      <PageHeader title={t('title')} subtitle={t('subtitle')} className="border-b border-primary-300 pb-6 mb-6" />
      <div className="grid grid-cols-4">
        <div className="col-span-2" />
        {[SubscriptionType.FREE, SubscriptionType.PRO].map((type) => (
          <div key={`${type}_subscription_details`}
               className={classNames('justify-between rounded-t-3xl text-secondary-50 p-4 flex flex-col gap-2', {
                 'bg-secondary': type === SubscriptionType.FREE,
                 'bg-primary-300': type === SubscriptionType.BASE,
                 'bg-primary-900': type === SubscriptionType.PRO,
               })}>
            {type === activeSubscription ? (
              <p
                className={classNames('text-center font-bold w-fit self-center rounded-md text-sm px-2 py-0.5 ml-1 uppercase', {
                  'bg-secondary-50 text-primary-900': type === SubscriptionType.PRO,
                  'bg-primary-900 text-secondary-50': type === SubscriptionType.BASE || type === SubscriptionType.FREE,
                })}>
                {t('activeSubscription')}
              </p>
            ) : <div />}
            <div className="justify-self-start">
              <h2 className="text-secondary-50 text-xl font-bold">{t(`${type.toLowerCase()}.title`)}</h2>
              <p className="text-secondary-50 py-2">{t(`${type.toLowerCase()}.subtitle`)}</p>
              <p className="text-secondary-50 pt-1 text-sm">{t('from')}</p>
              <div className="flex items-end">
                <span
                  className="text-secondary-50 text-4xl">&euro;{baseSubscriptionPrices[type as SubscriptionType]}</span>
                {t('perMonth')}
              </div>
            </div>
            <div>
              {type !== SubscriptionType.FREE && (
                <button
                  onClick={() => {
                    if (activeSubscription === type) {
                      navigate('/subscriptions/manage');
                    } else {
                      navigate('/subscriptions/upgrade', {
                        state: { subscriptionType: type },
                      });
                    }
                  }}
                  type="button"
                  className="px-2 transition-all font-extrabold justify-self-end text-primary-900 border-2 border-secondary-50 bg-secondary-50 rounded-lg text-lg text-center w-full py-2 hover:bg-transparent hover:text-secondary-50">
                  {activeSubscription === type ? t('manageSubscription') : t('subscribe')}
                </button>
              )}
            </div>
          </div>
        ))}

        <FeatureList />
        {[SubscriptionType.FREE, SubscriptionType.PRO].map((type) => (
          <SubscriptionFeatures key={`${type}_subscription_features`} subscriptionType={type} />
        ))}
      </div>
    </>
  );
}

function FeatureList() {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.subscriptions' });

  return (
    <div className="flex flex-col bg-secondary-200 pl-4 py-4 rounded-l-3xl col-span-2">
      <h3 className="py-4 text-lg font-bold">{t('functionalities')}</h3>
      {subscriptionFeatures.map((feature) => (
        <p className="py-3 pl-2 font-semibold" key={feature.i18nKey}>
          {t(feature.i18nKey)}
          {feature.isComingSoon && (
            <span
              className="bg-primary-900 text-secondary-50 rounded-md text-sm px-2 py-0.5 ml-1">{t('comingSoon')}</span>
          )}
        </p>
      ))}
    </div>
  );
}

type SubscriptionFeaturesProps = {
  subscriptionType: SubscriptionType;
}

function SubscriptionFeatures({ subscriptionType }: SubscriptionFeaturesProps) {
  return (
    <div className={classNames('flex flex-col items-center py-4', {
      'bg-secondary': subscriptionType === SubscriptionType.FREE,
      'bg-primary-300': subscriptionType === SubscriptionType.BASE,
      'bg-primary-900 rounded-br-3xl': subscriptionType === SubscriptionType.PRO,
    })}>
      <div className="h-14" />
      {subscriptionFeatures.map((feature) => (
        <div className="h-12 flex items-center justify-center">
          <div className="rounded-full w-8 h-8 bg-secondary-200 flex items-center justify-center">
            {feature[subscriptionType]
              ? <FaCheck strokeWidth={18} stroke={COLOR_PRIMARY_900} />
              : null}
          </div>
        </div>
      ))}
    </div>
  );
}
